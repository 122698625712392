import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Navbar, Nav, Button, Card, Accordion } from 'react-bootstrap';
import { FaGlobe } from 'react-icons/fa';
import { BsPlayCircle } from 'react-icons/bs';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/web/assets/mobirise-icons2/mobirise2.css';
import '../assets/web/assets/mobirise-icons/mobirise-icons.css';
import '../assets/dropdown/css/style.css';
import '../assets/socicon/css/styles.css';
import '../assets/theme/css/style.css';
import '../assets/mobirise/css/mbr-additional.css';
import video from '../assets/videos/Video.mp4';
import videoej1 from '../assets/videos/Videoej.mp4';
import videoej2 from '../assets/videos/Videoej2.mp4';
import videoej3 from '../assets/videos/Videoej3.mp4';
import './Main.css';
import logo from '../assets/img/logo.png';
import qfondoImage from '../assets/img/qfondo.png';
import qfondo2Image from '../assets/img/qfondo3.png';
import qfondo3Image from '../assets/img/qfondo2.png';
import 'animate.css';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate

const App = () => {
  const navigate = useNavigate(); // Inicializa el hook useNavigate

  const images = [qfondoImage, qfondo2Image, qfondo3Image];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000); // Cambia cada 4 segundos

    return () => clearInterval(interval); // Limpia el intervalo cuando el componente se desmonte
  }, [images.length]);

  const handleCatalogClick = () => {
    navigate('/catalogo'); // Redirige a la ruta /catalogo
  };

  return (
    <>
      {/* Navbar */}
      <section className="menu menu1 cid-ujiVnDsqCw" data-bs-version="5.1" id="menu1-0">
        <Navbar expand="lg" fixed="top">
          <Container fluid>
            <Navbar.Brand href="/">
              {/* Logo si lo necesitas */}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarSupportedContent">
              <span className="navbar-toggler-icon"></span>
            </Navbar.Toggle>
            <Navbar.Collapse id="navbarSupportedContent">
              <Nav className="ms-auto">
                {/* 
                <Nav.Link href="https://fashion.xbastudio.com">Acerca de</Nav.Link>
                <Nav.Link href="https://fashion.xbastudio.com">Productos</Nav.Link>
                <Nav.Link href="https://fashion.xbastudio.com">Contáctanos</Nav.Link>
                */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </section>

      {/* Header Section */}
      <section data-bs-version="5.1" className="header11 cid-ujiVqK8jbp" id="header11-1">
        <div className="mbr-overlay overlay" style={{ opacity: 0.9, backgroundColor: 'rgb(255, 255, 255)' }}>
          <video autoPlay muted loop id="background-video">
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={6} className="image-wrapper">
              <img className="responsive-image" src={images[currentImageIndex]} alt="Fashion XBA" />
            </Col>
            <Col xs={12} md className="text-wrapper text-center">
              <h1 className="mbr-section-title mb-3 display-1 animate__animated animate__bounceIn">
                <strong>XBA Fashion Laguna</strong>
              </h1>
              <Button className="btn-secondary display-7" onClick={handleCatalogClick}>Ver Catálogo</Button>
              <p className="mbr-text display-7 animate__animated animate__zoomIn">Moda Influencer al alcance de todos. Visita nuestros artículos de entrega inmediata.</p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Video Section */}
      <section className="video2 cid-ujj6XJiqjA" id="video2-6">
        <Container>
          <div className="mbr-section-head">
            <h4 className="mbr-section-title mb-0 display-2">
              <strong>Video Demostrativo</strong>
            </h4>
            <h5 className="mbr-section-subtitle mb-0 mt-2 display-7">Video de uno de nuestros productos.</h5>
          </div>
          <Row className="justify-content-center mt-4">
            <Col xs={12} md={4} className="video-block">
              <div className="video-wrapper">
                <iframe className="mbr-embedded-video" src={videoej1} width="1280" height="720" frameBorder="0" allowFullScreen></iframe>
              </div>
              <p className="mbr-text pt-2 display-4">Bikinis</p>
            </Col>
            <Col xs={12} md={4} className="video-block">
              <div className="video-wrapper">
                <iframe className="mbr-embedded-video" src={videoej2} width="1280" height="720" frameBorder="0" allowFullScreen></iframe>
              </div>
              <p className="mbr-text pt-2 display-4">Tres Piezas</p>
            </Col>
            <Col xs={12} md={4} className="video-block">
              <div className="video-wrapper">
                <iframe className="mbr-embedded-video" src={videoej3} width="1280" height="720" frameBorder="0" allowFullScreen></iframe>
              </div>
              <p className="mbr-text pt-2 display-4">Varios Colores</p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* FAQ Section */}
      <section className="content17 cid-ujj5ycYktk" id="content17-5" style={{ backgroundColor: "#f6f6f6" }}>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={10}>
              <div className="section-head align-center mb-4">
                <h3 className="mbr-section-title mb-0 display-2 animate__animated animate__bounceIn">
                  <strong>Preguntas Frecuentes</strong>
                </h3>
              </div>
              <Accordion id="bootstrap-toggle" className="accordionStyles tab-content">
                {[{
                  title: "¿En donde entregamos?",
                  content: "Entregamos en Residencial Hacienda, Calzada Gomez Morín (Torreón) y Falcón casi esquina con Hidalgo, Centro (Torreón)"
                }, {
                  title: "¿Entregan en punto medio?",
                  content: "Es posible acordar entregas en punto medio, dependiendo de la distancia y artículos"
                }, {
                  title: "¿Realizan envíos?",
                  content: "Sí, podemos enviar tu compra con nuestros repartidores de confianza o Uber/Didi flash, según tu preferencia"
                }, {
                  title: "¿Tienen local físico?",
                  content: "Sí contamos con local/oficina, puedes realizar cita"
                }].map((faq, index) => (
                  <Accordion.Item eventKey={index.toString()} key={index}>
                    <Accordion.Header>
                      <h6 className="panel-title-edit mb-0 display-7"><strong>{faq.title}</strong></h6>
                      <span className="sign"><BsPlayCircle /></span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="panel-text display-7">{faq.content}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Contacts Section */}
      <section className="contacts3 map1 cid-ujj59hkbFr" id="contacts3-3">
        <Container>
          <div className="mbr-section-head">
            <h3 className="mbr-section-title align-center mb-0 display-2 animate__animated animate__bounceIn">
              <strong>Ubicación</strong>
            </h3>
            <h5 className="mbr-section-subtitle align-center mb-0 mt-2 display-5 animate__animated animate__fadeIn">
              Para mayor información puedes encontrarnos en nuestras diferentes plataformas de contacto.
            </h5>
          </div>
        </Container>
        <div className="google-map"><iframe title="Google Map" src="https://maps.google.com/maps?q=26.1046115,-98.2888336&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" allowFullScreen></iframe></div>
      </section>
    </>
  );
};

export default App;
