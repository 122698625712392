import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Importa Link para navegación
import './Catalogo.css';
import fondo from '../assets/img/fondocatalogo.jpg';
import tallas from '../assets/img/tallas.png';
import tallam from '../assets/img/tallam.png';
import tallal from '../assets/img/tallal.png';

const Catalogo = () => {
  return (
    <div className="catalogo">
      <div className="talla-section">
        <div className="overlay"></div>
        <Row>
          <Col xs={12} md={4} className="column">
            <img src={tallas} alt="Imagen 1" className="column-image" />
            <Link to="/chica" className="talla-button">Tall XS-S</Link>
          </Col>
          <Col xs={12} md={4} className="column">
            <img src={tallam} alt="Imagen 2" className="column-image" />
            <Link to="/mediana" className="talla-button">Tall M</Link>
          </Col>
          <Col xs={12} md={4} className="column">
            <img src={tallal} alt="Imagen 3" className="column-image" />
            <Link to="/grande" className="talla-button">Tall L</Link>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Catalogo;
