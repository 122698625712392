import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Main from './components/Main';
import Live from './components/Live'
import Chica from './components/Chica'
import Mediana from './components/Mediana'
import Grande from './components/Grande'
import Catalogo from './components/Catalogo'
import Adminitracion from './components/Administracion'
import LayoutComponent from './components/Layout';

import { TransitionGroup, CSSTransition } from 'react-transition-group';


const App = () => {
  const location = useLocation();
  return (  
      <LayoutComponent>
  
  <Routes location={location}>
  <Route path="/" element={<Main />} />
  <Route path="/live" element={<Live />} />
  <Route path="/chica" element={<Chica />} />
  <Route path="/mediana" element={<Mediana />} />
  <Route path="/grande" element={<Grande />} />
  <Route path="/catalogo" element={<Catalogo />} />
  <Route path="/administracion" element={<Adminitracion />} />
          </Routes>
 
    </LayoutComponent>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
