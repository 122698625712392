import React from 'react';
import './Catalogo.css'; // Asegúrate de crear e importar tu archivo de estilos

// Función para construir URLs de imágenes
const importAll = (r) => {
  let images = [];
  r.keys().forEach((item) => {
    // Construye la URL relativa basada en la ruta del archivo
    const url = `${window.location.origin}/assets/catalogo/mediana/${item.replace('./', '')}`;
    console.log(images);
    images.push(url);
  });
  return images;
};

// Importa todas las imágenes
const images = importAll(require.context('../assets/catalogo/mediana', false, /\.(jpg|jpeg|png)$/));

const Mediana = () => {
  return (
    <div className="chica-gallery">
      {images.length > 0 ? (
        images.map((image, index) => (
          <div key={index} className="chica-gallery-item">
            <img src={image} alt={`Image ${index}`} />
          </div>
        ))
      ) : (
        <p>No se encontraron imágenes .</p>
      )}
    </div>
  );
};

export default Mediana;
