import React, { useState } from 'react';
import { Layout } from 'antd';
import { Tooltip, Fab } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import SupportAgent from '@mui/icons-material/SupportAgent';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';


import '../components/Layout.css';

import logo from '../assets/img/logo.png';
import { useNavigate } from 'react-router-dom';
import zIndex from '@mui/material/styles/zIndex';

const { Content } = Layout;

const LayoutComponent = ({ children }) => {

  const navigate = useNavigate();



  const fabStyle = {
    width: '50px', // 50% larger than the default 56px
    height: '50px', // 50% larger than the default 56px
    fontSize: '27px', // 50% larger than the default 24px
    backgroundColor: 'rgba(61, 6, 110, 0.6)',
    color: 'white',
    zIndex: '10'
  };

  

  return (
    <Layout className="layout">
      <Content className="content">
        {children}
      </Content>
      <div style={{ position: 'fixed', bottom: 20, right: 20, display: 'flex', flexDirection: 'column', gap: '10px', zIndex:'5' }}>
      {/*<Tooltip title="Perfil">
           <Fab style={fabStyle} onClick={() => navigate('/servicios')}>
            XL
          </Fab>
        </Tooltip>*/}
        <Tooltip title="Live Shop">
        <Fab  style={{ width: '50px',  height: '50px',  fontSize: '12px',  backgroundColor: 'rgba(8, 102, 255, 0.7)', color: 'white'}}
           onClick={() => navigate('/live')}>
            LIVE SHOP
          </Fab>
        </Tooltip>
        <Tooltip title="Catálogo">
          <Fab style={fabStyle} onClick={() => navigate('/catalogo')}>
         < ShoppingCartCheckoutIcon/>
          </Fab>
        </Tooltip>
        <Tooltip title="Facebook">
          <Fab  style={{ width: '50px',  height: '50px',  fontSize: '27px',  backgroundColor: 'rgba(8, 102, 255, 0.7)', color: 'white'}}
          onClick={() => window.open('https://www.facebook.com/xbafashion/', '_blank')}>
         < FacebookIcon/>
          </Fab>
        </Tooltip>
        <Tooltip title="WhatsApp">
          <Fab 
          style={{ width: '50px',  height: '50px',  fontSize: '27px',  backgroundColor: 'rgba(22, 229, 21, 0.8)', color: 'white'}}
           onClick={() => window.open('https://wa.me/528713358960', '_blank')}>
            <WhatsAppIcon />
          </Fab>
        </Tooltip>
        <Tooltip title="Inicio">
          <Fab style={fabStyle} onClick={() => navigate('/')}>
            <HomeIcon />
          </Fab>
        </Tooltip>
      </div>
      {/*<img 
        src={logo} 
        alt="Logo" 
        className="logomain"        
      />*/}
    </Layout>
  );
};

export default LayoutComponent;
