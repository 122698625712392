import React, { useState, useEffect } from 'react';
import videoSrc from '../assets/videos/Webcam1.mp4';
import bellSound from '../assets/sounds/bell.mp3'; // Ruta del sonido del timbre
import { FaBell } from 'react-icons/fa';
import { FaConciergeBell } from "react-icons/fa";
import { Modal, Input, Button } from 'antd';
import 'antd/dist/reset.css'; // Asegúrate de importar el CSS de Ant Design
import './Live.css';

const Live = () => {
  const [time, setTime] = useState(new Date().toLocaleTimeString());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [timerId, setTimerId] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const handleFloatButtonClick = () => {
    const audio = new Audio(bellSound);
    audio.play();

    if (!timerId) {
      const newTimerId = setTimeout(() => {
        setIsModalOpen(true);
        setTimerId(null); // Reset timerId after modal is opened
      }, 5000); // 8 segundos
      setTimerId(newTimerId);
    }
  };

  const handleMessageSend = () => {
    const whatsappUrl = `https://wa.me/528713358960?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
    setIsModalOpen(false);
  };

  return (
    <div className="webcam-section">
      <video className="background-video" autoPlay loop muted>
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="live-indicator">LIVE</div>
      <div className="current-time">Cam {time}</div>
      <div className="float-button" onClick={handleFloatButtonClick}>
        <FaConciergeBell />
      </div>

      <Modal
        title="Hola!"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}        
        footer={[
          <Button key="cancel" onClick={() => setIsModalOpen(false)}
          style={{backgroundColor:"#e6d9ff"}}
          >
            Cancelar
          </Button>,
          <Button
            key="send"
            type="primary"
            style={{backgroundColor:"#3d066e"}}
            onClick={handleMessageSend}
          >
            Enviar
          </Button>
        ]}
      >
        <p>¿Deseas iniciar un meet? o si prefieres puedes iniciar una conversación por chat:</p>
        <Input.TextArea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows={4}
        />
      </Modal>
    </div>
  );
};

export default Live;
