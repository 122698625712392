import React, { useState } from 'react';
import { Collapse, Card, Typography, Row, Col, Button, Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { FaTrash } from 'react-icons/fa'; // Para el icono de eliminar
import './Administracion.css'; // Asegúrate de crear e importar tu archivo de estilos

const { Panel } = Collapse;
const { Title } = Typography;

// Función para construir URLs de imágenes
const importAll = (r, subfolder) => {
  let images = [];
  r.keys().forEach((item) => {
    // Construye la URL relativa basada en la subcarpeta
    const url = `${window.location.origin}/assets/catalogo/${subfolder}/${item.replace('./', '')}`;
    images.push(url);
  });
  return images;
};

// Importa todas las imágenes de las carpetas
const chicaImages = importAll(require.context('../assets/catalogo/chica', false, /\.(jpg|jpeg|png)$/), 'chica');
const medianaImages = importAll(require.context('../assets/catalogo/mediana', false, /\.(jpg|jpeg|png)$/), 'mediana');
const grandeImages = importAll(require.context('../assets/catalogo/grande', false, /\.(jpg|jpeg|png)$/), 'grande');

// Función para mover la imagen a la carpeta 'Agotado'
const moveToAgotado = (imagePath, subfolder) => {
  // Aquí debes implementar la lógica para mover la imagen al servidor.
  // Como esto es una simulación, solo mostramos un mensaje.
  console.log(`Moviendo ${imagePath} a ${subfolder}/Agotado`);
};

const Administracion = () => {
  const [expandedKey, setExpandedKey] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentSubfolder, setCurrentSubfolder] = useState('');

  const handleToggle = (key) => {
    setExpandedKey(expandedKey === key ? null : key);
  };

  const handleDeleteClick = (image, subfolder) => {
    setSelectedImage(image);
    setCurrentSubfolder(subfolder);
    setConfirmDelete(true);
  };

  const handleConfirmDelete = () => {
    if (selectedImage && currentSubfolder) {
      moveToAgotado(selectedImage, currentSubfolder);
      setConfirmDelete(false);
      setSelectedImage(null);
      setCurrentSubfolder('');
    }
  };

  const handleCancelDelete = () => {
    setConfirmDelete(false);
    setSelectedImage(null);
    setCurrentSubfolder('');
  };

  const renderImages = (images, subfolder) => (
    <Row gutter={16}>
      {images.length > 0 ? (
        images.map((image, index) => (
          <Col span={8} key={index} style={{ marginBottom: '16px', position: 'relative' }}>
            <Card
              hoverable
              cover={
                <div style={{ position: 'relative' }}>
                  <img alt={`Image ${index}`} src={image} style={{ width: '100%', height: 'auto' }} />
                  <Button
                    shape="circle"
                    icon={<FaTrash />}
                    size="large"
                    style={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                      backgroundColor: 'red',
                      color: 'white',
                    }}
                    onClick={() => handleDeleteClick(image, subfolder)}
                  />
                </div>
              }
            />
          </Col>
        ))
      ) : (
        <Col span={24}><p>No se encontraron imágenes.</p></Col>
      )}
    </Row>
  );

  return (
    <div style={{ padding: '20px' }}>
      <Title level={2}>Administración de Imágenes</Title>
      <Collapse
        activeKey={expandedKey}
        onChange={handleToggle}
        accordion
      >
        <Panel header="Talla Chica" key="1">
          {renderImages(chicaImages, 'chica')}
        </Panel>
        <Panel header="Talla Mediana" key="2">
          {renderImages(medianaImages, 'mediana')}
        </Panel>
        <Panel header="Talla Grande" key="3">
          {renderImages(grandeImages, 'grande')}
        </Panel>
      </Collapse>

      <Modal
        title="Confirmar Eliminación"
        visible={confirmDelete}
        onOk={handleConfirmDelete}
        onCancel={handleCancelDelete}
        okText="Confirmar"
        cancelText="Cancelar"
      >
        <p>¿Estás seguro de que quieres mover esta imagen a la carpeta 'Agotado'?</p>
      </Modal>
    </div>
  );
};

export default Administracion;
